<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogTopBanner"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Top Banner</span
              >
              <span class="headline" v-else>Edit Top Banner</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTopBanner = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        solo
                        dense
                        v-model="name_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tiêu đề:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><Editor v-model="title_input"></Editor
                    ></v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><Editor v-model="content_input"></Editor
                    ></v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ảnh đại diện:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <ImageCompress :done="getFileAvatar"></ImageCompress>
                      <template v-if="cover_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="cover_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ref code:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <multiselect
                        v-model="ref_code_selected"
                        :options="ref_codes"
                        tag-placeholder="Tạo ref code này"
                        placeholder="Chọn hoặc tạo mới ref code"
                        label="name"
                        track-by="id"
                        :multiple="true"
                        :taggable="true"
                        @tag="addRefCode"
                      ></multiselect>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEdit(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEdit(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogTopBanner = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ImageCompress from "@/components/image/ImageCompress";
import Editor from "@/components/ckeditor5/Editor";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "TopBannerModal",
  components: {
    Editor,
    ImageCompress,
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    ref_codes: {
      type: Array,
      default: () => [],
    },
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    top_banner: {},
  },
  data() {
    return {
      is_call_api: false,
      title_input: "",
      cover_data: null,
      cover_file: null,
      content_input: "",
      // ref_codes: [],
      ref_code_selected: [],
      top_banner_id: null,
      name_input: "",
    };
  },
  computed: {
    dialogTopBanner: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogTopBanner(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.title_input = "";
        this.cover_data = null;
        this.cover_file = null;
        this.content_input = "";
        this.name_input = "";
        this.ref_code_selected = [];
      } else {
        this.top_banner_id = this.top_banner.id;
        this.name_input = this.top_banner.name;
        this.title_input =
          this.top_banner.title == null ? "" : this.top_banner.title;
        this.content_input =
          this.top_banner.body == null ? "" : this.top_banner.body;
        this.ref_code_selected = JSON.parse(
          JSON.stringify(this.top_banner.ref_code)
        );
        this.cover_data = this.top_banner.cover;
        this.cover_file = null;
      }
    },
    getFileAvatar(obj) {
      this.cover_file = obj.file;
      this.cover_data = obj.blob;
    },
    addRefCode(newRef) {
      let data = {
        id: newRef,
        name: newRef,
      };
      this.ref_codes.push(data);
      this.ref_code_selected.push(data);
    },
    validateData() {
      let flat = true;
      // if (this.name_input === null || this.name_input === '') {
      //   this.$toasted.error('Hãy nhập name !!', { theme: "toasted-primary", position: "top-right", duration : 4000 });
      //   flat = false;
      // }
      if (this.title_input === null || this.title_input === "") {
        this.$toasted.error("Hãy nhập tiêu đề !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.content_input === null || this.content_input === "") {
        this.$toasted.error("Hãy nhập nội dung!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEdit(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      if (!(this.name_input === null || this.name_input === ""))
        formData.append("name", this.name_input);
      formData.append("title", this.title_input);
      formData.append("body", this.content_input);
      formData.append(
        "ref_codes",
        this.ref_code_selected.length <= 0
          ? []
          : this.ref_code_selected.map((e) => e.id)
      );
      if (this.cover_file != null) formData.append("cover", this.cover_file);
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/top-banner", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataTopBanner");
              vm.is_call_api = false;
              vm.dialogTopBanner = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/top-banner/" + vm.top_banner_id, formData)
          .then(function (res) {
            if (res.status === 202) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataTopBanner");
              vm.is_call_api = false;
              vm.dialogTopBanner = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
