<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí top banner</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogTopBanner"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm top banner</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Title</th>
                    <th scope="col">Body</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(top_banner, i) in top_banners">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ top_banner.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="top_banner.title"
                        ></p>
                      </td>
                      <td style="max-width: 600px">
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="top_banner.body"
                        ></p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editTopBanner(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteTopBanner(top_banner.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!top_banners.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <TopBannerModal
      :show_dialog="dialogTopBanner"
      :type_form="type_form"
      :top_banner="top_banner"
      :ref_codes="ref_codes"
      @setDialog="(e) => (this.dialogTopBanner = e)"
      @resetDataTopBanner="resetDataTopBanner"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import TopBannerModal from "@/view/pages/top-banner/modal/TopBannerModal";
import Swal from "sweetalert2";
export default {
  name: "TopBannerList",
  components: {
    Loading: () => import("vue-loading-overlay"),
    TopBannerModal,
  },
  data() {
    return {
      is_call_api: false,
      top_banners: [],
      top_banner: {},
      dialogTopBanner: false,
      type_form: null,
      ref_codes: [],
    };
  },
  created() {
    this.getAllTopBanner();
    this.getAllRefCode();
  },
  methods: {
    async getAllTopBanner() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/top-banner");
        if (res.status === 200) {
          vm.top_banners = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllRefCode() {
      let vm = this;
      try {
        let res = await ApiService.get("prep-app/ref-code/limit");
        if (res.status === 200) {
          vm.ref_codes = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDiaLogTopBanner() {
      this.type_form = "add";
      this.dialogTopBanner = true;
    },
    editTopBanner(index) {
      this.top_banner = this.top_banners[index];
      this.type_form = "update";
      this.dialogTopBanner = true;
    },
    deleteTopBanner(top_banner_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/top-banner/" + top_banner_id)
            .then(function (res) {
              if (res.status === 202) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllTopBanner();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    resetDataTopBanner() {
      this.getAllTopBanner();
      this.getAllRefCode();
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
